<template>
  <b-row>
    <b-col cols="12">
      <RegisterForm v-if="isReady" :is-edit="true" :is-approved="isApproved" />
    </b-col>
  </b-row>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import { BRow, BCol } from "bootstrap-vue";
import { mapActions } from "vuex";
import RegisterForm from "./RegisterForm.vue";

export default {
  components: {
    BRow,
    BCol,
    RegisterForm,
  },
  data() {
    return {
      isApproved: false,
      isReady: false,
    };
  },
  async created() {
    const { id, isApproved } = router.currentRoute.params;
    await this.getProductCategoriesForVendor({
      take: 100,
      skip: 0,
    });
    await this.getRegions();
    await this.getBranches();
    if (isApproved) {
      this.isApproved = true;
      this.getApprovedProductById(id);
    } else {
      this.isApproved = false;
      this.getProductById(id);
    }
    this.isReady = true;
  },
  methods: {
    ...mapActions("products", [
      "getProductById",
      "getRegions",
      "getProductCategoriesForVendor",
      "getApprovedProductById",
      "getBranches",
    ]),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
